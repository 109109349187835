<template>
  <div>
    <navbar-component></navbar-component>
    <main>
      <div
        class="relative pt-16 pb-32 flex content-center items-center justify-center shadow-lg"
        style="min-height: 100vh;"
      >
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
        >
          <video autoplay muted loop preload="auto" playsinline class="object-cover w-full h-full object-top">
            <source src="/images/img/videos-brota/teste2.webm" type="video/webm">
            Seu navegador não suporta vídeo de fundo.
          </video>
          <span
            id="blackOverlay"
            class="w-full h-full relative opacity-75 bg-black"
          ></span>
        </div>
        <div
          class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
          style="height: 70px;"
        >
        </div>
      </div>
      <section id="festa" class="pb-20 bg-gray-300 -mt-24 hidden">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap">
            <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
              </div>
            </div>
          </div>
          <div class="flex flex-wrap items-center mt-32">
            <div class="w-full md:w-5/12 px-4 mr-auto ml-auto">
              <div
                class="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100"
              >
                <i class="fas fa-user-friends text-xl"></i>
              </div>
              <h3 class="text-3xl mb-2 font-semibold leading-normal">
                A arte existe porque a vida não basta
              </h3>
              <p
                class="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700"
              >
                E porquê não viver a arte?
              </p>
              <p
                class="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-700"
              >
                Pensando nisso a <strong>Pico</strong> traz um coletivo de artistas para dentro da sua casa buscando ampliar horizontes, sorrisos e ritmos, conectando gerações da zona oeste carioca fomentando a cena independente do Hip Hop trazendo um evento com clima de resenha onde você pode ser você mesmo, encontrar, escutar e conhecer pessoas que curtem você sendo você.
              </p>
            </div>
            <div class="w-full md:w-4/12 px-4 mr-auto ml-auto">
              <div
                class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-pink-600"
              >
                <img
                  alt="..."
                  src="/images/img/fotos-brota/foto-2.jpg"
                  class="w-full align-middle rounded-t-lg"
                />
                <blockquote class="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    class="absolute left-0 w-full block"
                    style="height: 95px; top: -94px;"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      class="text-pink-600 fill-current"
                    ></polygon>
                  </svg>
                  <h4 class="text-xl font-bold text-white">
                    Bar do Brota
                  </h4>
                  <p class="text-md font-light mt-2 text-white">
                    O Brota na Pico quer entregar pro público uma experiência sensorial, e para somar ao sistema de som e luz de alta qualidade, o Bar está inserido nessa equação com drinks exclusivos, pra você saborear e elevar a sua experiência.
                    <br/>Acesse nosso cardápio.
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ShowDjs class="hidden"/>
      <section id="parceiro" class="pt-10 pb-10 bg-gray-500">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center text-center mb-24">
            <div class="w-full lg:w-6/12 px-4">
              <h2 class="text-4xl font-semibold">Nossos Parceiros</h2>
              <p class="text-lg leading-relaxed m-4">
                Sem essa rapaziada aqui, o evento não aconteceria
              </p>
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img
                  alt="..."
                  src="/images/img/parceiros/agl.jpg"
                  class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px;"
                />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">AGL</h5>
                  <div class="mt-6">
                    <a
                      href="#"
                      class="bg-gray-800 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1 py-1.5 px-2"
                      type="button"
                    >
                      <i class="fab fa-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img
                  alt="..."
                  src="/images/img/parceiros/criative.jpg"
                  class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px;"
                />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Criative</h5>
                  <div class="mt-6">
                    <a
                      href="#"
                      class="bg-gray-800 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1 py-1.5 px-2"
                      type="button"
                    >
                      <i class="fab fa-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img
                  alt="..."
                  src="/images/img/parceiros/manifestu.jpg"
                  class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px;"
                />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Manifest-U</h5>
                  <div class="mt-6">
                    <a
                      href="https://www.instagram.com/mani.festu"
                      class="bg-gray-800 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1 py-1.5 px-2"
                      type="button"
                    >
                      <i class="fab fa-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="section-form-vem-brota" class="pb-10 relative block bg-gray-900">
        <div
          class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          style="height: 80px;"
        >
        </div>
        <div id="vem-pro-brota" class="container mx-auto px-4 lg:pt-24 lg:pb-64">
          <div class="flex flex-wrap text-center justify-center">
            <div class="w-full lg:w-6/12 px-4">
              <h2 class="text-4xl font-semibold text-white">Quer Colar no Brota Na Pico?</h2>
              <p class="text-lg leading-relaxed mt-4 mb-4 text-gray-500">
                Preencha o Formulário abaixo. Enviaremos um email com um link para que você confirme a presença. Depois de clicar no link, é só Brotar :)
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="relative block py-24 lg:pt-0 bg-gray-900">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center lg:-mt-64 -mt-48">
            <div class="w-full lg:w-6/12 px-4">
              <div
                class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300"
              >
              <SavePartyGuest/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component></footer-component>
  </div>
</template>
<script setup>
import { usePartyGuests } from "@/stores/partyGuestsStore.js";
import { onMounted } from "vue";
import { useToast } from "vue-toastification";
import { useRoute } from "vue-router";
import NavbarComponent from "../components/Navbar.vue";
import FooterComponent from "../components/Footer.vue";
import SavePartyGuest from "@/components/PartyGuests/SavePartyGuest.vue";
import ShowDjs from "@/components/DjParty/ShowDjs.vue";

const route = useRoute();
const toast = useToast();
const partyGuestsStore = usePartyGuests();

onMounted(() => {
  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function(e) {
      e.preventDefault();
      const targetId = this.getAttribute('href');
      document.querySelector(targetId).scrollIntoView({
        behavior: 'smooth'
      });
    });
  });

  if (route.params.token !== undefined) {
    partyGuestsStore.confirmGuest(route.params.token)
        .then(response => {
          if (response.data.action) {
            toast.success('Sua presença foi confirmada com sucesso.');
          }
        })
        .catch(error => {
          console.error(error.message);
          toast.error('Ocorreu um erro. Contate o administrador do site.');
        });
  }
})
</script>
<style scoped>
video {
  object-position: center top; /* ou ajuste como '0 10%' para descer um pouco */
}
</style>