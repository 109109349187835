import { defineStore, acceptHMRUpdate } from "pinia"
import axios from 'axios'

export const useWhoInvited = defineStore({
  id: 'who_invited',
  state: () => ({
    isLoadingWhoInvited: true,
    who_invited: {
      id: '',
      name: '',
      nickname: '',
      who_invited_id: ''
    },
    who_inviteds: [],
  }),
  actions: {
    async getWhoInvited() {
      this.isLoadingWhoInvited = true;
      try {
        return await axios.get('/who-invited');
      } catch (error) {
        throw error;
      } finally {
        this.isLoadingWhoInvited = false;
      }
    },
    setWhoInviteds(who_inviteds) {
      this.who_inviteds = who_inviteds;
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useWhoInvited, import.meta.hot))
}