<template>
  <div id="text-request" class="h-screen flex text-2xl items-center justify-center">

  </div>
</template>
<script setup>
import { usePartyGuests } from "@/stores/partyGuestsStore.js";
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import Swal from "sweetalert2";

const partyGuestsStore = usePartyGuests();
const route = useRoute();

onMounted(() => {
  const textRequest = document.getElementById("text-request");
  textRequest.innerHTML = "Consultado QRCode..."
  partyGuestsStore.checkQrCode(route.params.token)
    .then(response => {
      Swal.fire({
        icon: "success",
        text: response.data.message
      });
      textRequest.innerHTML = "Validado";
    })
    .catch(error => {
      Swal.fire({
        icon: "error",
        text: 'QRCode não Validado.'
      });
      textRequest.innerHTML = "QRCode Inválido";
    });
})
</script>