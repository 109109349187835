import { createApp } from "vue";
import { createPinia } from 'pinia'
import './services/Http.js'
import router from"./routes/index.js";
import Toast from 'vue-toastification'
import './assets/css/satoshi.css'
import './assets/css/style.css'
// views

import Landing from './views/Landing.vue'

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";

// mouting point for the whole app

import App from "@/App.vue";

// routes

createApp(App)
  .use(createPinia())
  .use(router)
  .use(Toast)
  .mount("#app");
