<template>
  <Form
    :validation-schema="validationSchema"
    @submit="changePassword"
    v-slot="{ errors }"
  >
    <div class="mt-1">
      <label for="new-password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" :class="{'text-red-700 dark:text-red-500' : errors.new_password}">Nova Senha</label>
      <Field name="new_password"
             id="new-password"
             type="password"
             :class="{'bg-red-50 border border-red-500 text-red-900 focus:ring-red-500 dark:text-red-500 dark:border-red-500 focus:border-red-500' : errors.new_password}"
             class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg shadow focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      />
      <ErrorMessage name="new_password" class="text-red-600"/>
    </div>
    <div class="mt-5" >
      <label for="retype-paswword" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" :class="{'text-red-700 dark:text-red-500' : errors.retype_password}">Redigite a Senha</label>
      <Field name="retype_password"
             id="retype-paswword"
             type="password"
             :class="{'bg-red-50 border border-red-500 text-red-900 focus:ring-red-500 dark:text-red-500 dark:border-red-500 focus:border-red-500' : errors.retype_password}"
             class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      />
      <ErrorMessage name="retype_password" class="text-red-600"/>
    </div>
    <div class="mt-5">
      <button type="submit" class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
        Alterar Senha
      </button>
    </div>
  </Form>
</template>
<script setup>
import { Form, Field, ErrorMessage } from "vee-validate";
import { toTypedSchema } from "@vee-validate/zod";
import { useAuthStore } from "@/stores/authStore.js";
import { useToast } from "vue-toastification";
import { Modal } from "flowbite";
import { z } from 'zod';

const authStore = useAuthStore();
const toast = useToast();
const emit = defineEmits(['changedPassword'])
const validationSchema = toTypedSchema(z.object({
  new_password: z
    .string({
      required_error: "Campo obrigatório",
    })
    .min(6, "Tamanho inválido. Mínimo de 6 caracteres"),
  retype_password: z.string({
    required_error: "Campo obrigatório",
  })
}).refine((data) => data.new_password === data.retype_password, {
  message: "As senhas devem ser iguais",
  path: ["retype_password"], // especifica onde o erro deve aparecer
}))

const changePassword = () => {
  const password = document.getElementById("new-password");
  authStore.changePassword(password.value)
    .then(response => {
      const target = document.getElementById('modal-is-first-access');
      const modal = new Modal(target, {});
      modal.hide();
      authStore.user.is_first_access = 0;
      emit('changedPassword', true);
    })
    .catch(error => {
      console.error(error.message);
      emit('changedPassword', false);
    })
};
</script>