import { useToast } from "vue-toastification";
import { useRoute } from "vue-router";
import NProgress from 'nprogress';
import axios from 'axios'

// Contador de requisições ativas
let activeRequests = 0;

if (import.meta.env.VITE_APP_ENV === 'local') {
    axios.defaults.baseURL = 'http://api.sitebrota.localhost/api'
} else {
    axios.defaults.baseURL = 'https://api.brotanapico.com.br/api'
}

// Adicione um interceptor de requisição
axios.interceptors.request.use(config => {
    if (activeRequests === 0) {
        NProgress.start();
    }
    activeRequests++;
    return config;
}, error => {
    return Promise.reject(error);
});

// Adicione um interceptor de resposta para tratar os erros
axios.interceptors.response.use(
  (response) => {
      activeRequests--;
      if (activeRequests === 0) {
          NProgress.done();
      }
      // Se a resposta for bem-sucedida, retorne a resposta diretamente
      return response;
  },
  (error) => {
      activeRequests--;
      if (activeRequests === 0) {
          NProgress.done();
      }

      const toast = useToast();
      const route = useRoute();

      // Se ocorrer um erro na resposta (status code não 2xx), trate-o aqui
      if (error.response) {
          const statusCode = error.response.status;
          // Trate os diferentes status codes de erro aqui
          switch (statusCode) {
              case 400:
                  toast.error('Requisição inválida');
                  break;
              case 401:
                  console.log('aqui')
                  toast.error(error.response.data.message);
                  break;
              case 403:
                  toast.error(error.response.data.message);
                  break;
              case 404:
                  toast.error(error.response.data.message);
                  break;
              case 422:
                  toast.error(error.response.data.message);
                  break;
              case 500:
                  toast.error(error.response.data.message);
                  break;
              default:
                  // Tratar outros erros
                  console.error('Erro não tratado: ' + statusCode);
          }
      }
      // Retorne a promessa rejeitada com o erro
      return Promise.reject(error);
  }
);
