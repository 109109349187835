<template>
  <section id="dj-casa" class="relative py-20">
    <div
        class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px;"
    >
      <svg
          class="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
      >
        <polygon
            class="text-white fill-current"
            points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>
    <div class="container mx-auto px-4">
      <div class="items-center flex flex-wrap">
        <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
          <img
              alt="dj-lek"
              id="foto-dj-0"
              class="max-w-full rounded-lg shadow-lg"
              src="/images/img/fotos-brota/lekke-dj-smoke-small.jpg"
          />
          <img
              alt="dj pedro"
              id="foto-dj-1"
              class="max-w-full rounded-lg shadow-lg hidden"
              src="/images/img/fotos-brota/dj-pedro-araujo-small.jpg"
          />
          <div class="flex justify-center mt-4 space-x-4">
            <button
                class="bg-gray-200 p-2 rounded-full hover:bg-gray-300 transition duration-300"
                @click="prevDj"
                aria-label="Anterior"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M15 10a1 1 0 01-1 1H7.41l3.3 3.3a1 1 0 01-1.42 1.4l-5-5a1 1 0 010-1.4l5-5a1 1 0 011.42 1.4L7.41 9H14a1 1 0 011 1z" clip-rule="evenodd" />
              </svg>
            </button>
            <button
                class="bg-gray-200 p-2 rounded-full hover:bg-gray-300 transition duration-300"
                @click="nextDj"
                aria-label="Próximo"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5 10a1 1 0 001-1h6.59l-3.3-3.3a1 1 0 011.42-1.4l5 5a1 1 0 010 1.4l-5 5a1 1 0 01-1.42-1.4l3.3-3.3H6a1 1 0 00-1-1z" clip-rule="evenodd" />
              </svg>
            </button>
          </div>
        </div>
        <div class="w-full md:w-5/12 ml-auto mr-auto px-4">
          <div class="md:pr-12">
            <div
                class="text-red-800 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-red-600"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="-4 -2 24 24">
                <path fill="currentColor" d="M4 0h8a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4m0 2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm4 15A5 5 0 1 1 8 7a5 5 0 0 1 0 10m0-2a3 3 0 1 0 0-6a3 3 0 0 0 0 6M4 3h4a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1m7 0h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1M8 13a1 1 0 1 1 0-2a1 1 0 0 1 0 2" />
              </svg>
            </div>
            <div id="description-dj-0">
              <h3 class="text-3xl font-semibold">Lek DJ</h3>
              <p class="mt-4 text-lg leading-relaxed text-gray-600">
                Iniciando estrada como DJ em 2014, minha jornada começou no projeto
                social CUFA, onde aprendi com os mestres Jota L DJ e DJ PC Junior.
                Também tive a oportunidade de passar pelo curso Fórmula X, com o DJ
                Bruno X, que me ajudou a aprimorar ainda mais minhas habilidades na
                discotecagem.
              </p>
            </div>
            <div id="description-dj-1" class="hidden">
              <h3 class="text-3xl font-semibold">DJ Pedro Araújo</h3>
              <p class="mt-4 text-lg leading-relaxed text-gray-600">
                DJ Pedro Araújo Rio de Janeiro, baixada Fluminense São João de Meriti, 25 anos reconhecido por sua
                versatilidade, início sua jornada no mundo da discotecagem  em 2012 participando na residência da Black Music e da cultura Hip hop e nos eventos black seu bairro, DJ Pedro Araújo tem como principal característica nos toca discos propostas musical irreverentes, apresentação de brasilidades e intencionalidade na composição de seus sets
              </p>
            </div>
            <ul class="list-none mt-6">
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                        <span
                            class="text-xs font-semibold inline-block py-2 px-2 uppercase rounded-full text-red-600 bg-red-200 mr-3"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M21 3v12.5a3.5 3.5 0 0 1-3.5 3.5a3.5 3.5 0 0 1-3.5-3.5a3.5 3.5 0 0 1 3.5-3.5c.54 0 1.05.12 1.5.34V6.47L9 8.6v8.9A3.5 3.5 0 0 1 5.5 21A3.5 3.5 0 0 1 2 17.5A3.5 3.5 0 0 1 5.5 14c.54 0 1.05.12 1.5.34V6z" />
                          </svg>
                        </span>
                  </div>
                  <div>
                    <h4 class="text-gray-600">
                      RNB
                    </h4>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                        <span
                            class="text-xs font-semibold inline-block py-2 px-2 uppercase rounded-full text-red-600 bg-red-200 mr-3"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M21 3v12.5a3.5 3.5 0 0 1-3.5 3.5a3.5 3.5 0 0 1-3.5-3.5a3.5 3.5 0 0 1 3.5-3.5c.54 0 1.05.12 1.5.34V6.47L9 8.6v8.9A3.5 3.5 0 0 1 5.5 21A3.5 3.5 0 0 1 2 17.5A3.5 3.5 0 0 1 5.5 14c.54 0 1.05.12 1.5.34V6z" />
                          </svg>
                        </span>
                  </div>
                  <div>
                    <h4 class="text-gray-600">Charme</h4>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                        <span
                            class="text-xs font-semibold inline-block py-2 px-2 uppercase rounded-full text-red-600 bg-red-200 mr-3"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M21 3v12.5a3.5 3.5 0 0 1-3.5 3.5a3.5 3.5 0 0 1-3.5-3.5a3.5 3.5 0 0 1 3.5-3.5c.54 0 1.05.12 1.5.34V6.47L9 8.6v8.9A3.5 3.5 0 0 1 5.5 21A3.5 3.5 0 0 1 2 17.5A3.5 3.5 0 0 1 5.5 14c.54 0 1.05.12 1.5.34V6z" />
                          </svg>
                        </span>
                  </div>
                  <div>
                    <h4 class="text-gray-600">Hip Hop</h4>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { reactive } from "vue";
import Utils from '@/resources/utils.js';

const totalDjs = reactive({
  current_dj: 0,
  totalDjs: 1
});

const nextDj = () => {
  if (totalDjs.current_dj < totalDjs.totalDjs) {
    Utils.fadeOut(`foto-dj-${totalDjs.current_dj}`)
        .then(() => {
          totalDjs.current_dj = totalDjs.current_dj + 1;
          Utils.fadeIn(`foto-dj-${totalDjs.current_dj}`);
        });

    Utils.fadeOut(`description-dj-${totalDjs.current_dj}`)
        .then(() => {
          Utils.fadeIn(`description-dj-${totalDjs.current_dj}`);
        });
  }
}

const prevDj = () => {
  if (totalDjs.current_dj > 0) {
    Utils.fadeOut(`foto-dj-${totalDjs.current_dj}`)
        .then(() => {
          totalDjs.current_dj = totalDjs.current_dj - 1;
          Utils.fadeIn(`foto-dj-${totalDjs.current_dj}`);
        });

    Utils.fadeOut(`description-dj-${totalDjs.current_dj}`)
        .then(() => {
          Utils.fadeIn(`description-dj-${totalDjs.current_dj}`);
        });
  }
}
</script>
