import axios from "axios";
import { defineStore, acceptHMRUpdate } from "pinia";

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        authenticated: false,
        email: '',
        password: '',
        user: {}
    }),
    actions: {
        async login() {
            return await axios.post('/login', {
                email: this.email,
                password: this.password
            })
        },
        async getUserLogged() {
            return await axios.get('/user-logged')
        },
        async refreshToken() {
            return await axios.post('/refresh-token')
        },
        async logout() {
            return await axios.post('/logout')
        },
        resetUser() {
            this.user = {}
        },
        setAuthenticated(status) {
            this.authenticated = status
        },
        setUser(user) {
            this.user = user
        },
        doLogoff() {
            this.resetUser()
            this.setAuthenticated(false)
        },
        async getUserAuthenticated() {
            return await axios.get('/usuario/usuario-autenticado')
        },
        async changePassword(password) {
            return await axios.patch('/users/change-password', {
                password: password
            })
        }
    },
    getters: {
        getAuthenticated: (state) => {
            return state.authenticated
        },
        getUser: (state) => {
            return state.user
        },
        isAdmin: (state) => {
            return state.user.role === 'admin';
        }
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}