<template>
  <router-view />
</template>
<script setup>
import { useParties } from "@/stores/partiesStore.js";
import { onMounted } from "vue";

const partiesStore = useParties();

onMounted(() => {
  partiesStore.getParty()
      .then(response => {
        partiesStore.setParty(response.data.party);
      });
})
</script>
