import { defineStore, acceptHMRUpdate } from "pinia"
import axios from 'axios'

export const useParties = defineStore({
  id: 'party',
  state: () => ({
    isLoadingParties: false,
    party: {
      id: '',
      name: '',
      slug: '',
      date_party: '',
      status_party: ''
    },
    parties: [],
  }),
  actions: {
    async getParty() {
      try {
        return await axios.get('/parties');
      } catch (error) {
        throw error;
      }
    },
    async getParties() {
      try {
        return await axios.get('/parties/get');
      } catch (error) {
        throw error;
      }
    },
    setParty(party) {
      this.party = party;
    },
    setParties(parties) {
      this.parties = parties;
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useParties, import.meta.hot))
}