<template>
  <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 bg-gray-200">
    <a href="/" class="flex items-center mb-6 text-2xl font-semibold text-gray-900">
      <img class="mr-2" src="/images/img/brota-na-pico-vertical-preto-peq.png" alt="logo">
    </a>
    <div class="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
      <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
        <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
          Entre com a sua conta
        </h1>
        <form class="space-y-4 md:space-y-6" action="#">
          <div>
            <label for="email" class="block mb-2 text-sm font-medium text-gray-900">Seu email</label>
            <input v-model="authStore.email" type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" placeholder="Seu Email">
          </div>
          <div>
            <label for="password" class="block mb-2 text-sm font-medium text-gray-900">Sua Senha</label>
            <input v-model="authStore.password" type="password" name="password" id="password" placeholder="Sua Senha" class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5">
          </div>
          <button type="button" @click="doLogin" class="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Logar</button>
        </form>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useAuthStore } from "@/stores/authStore.js";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import LocalStorage from "@/services/LocalStorage.js";

const toast = useToast();
const authStore = useAuthStore();
const router = useRouter();
const doLogin = () => {

  if (authStore.email === '' || authStore.password === '') {
    toast.error('Preencha todos os campos', {
      timeout: 5000
    })
    return
  }

  authStore.login()
    .then(response => {
      if (response.data.action) {
        let token = response.data.authorization.token;
        LocalStorage.set('token_brota_api', JSON.stringify(token));
        authStore.email = '';
        authStore.password = '';
        authStore.user = response.data.authorization.user;
        router.push({name: 'dashboard'});
      }
    })
}
</script>